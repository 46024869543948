.sidebarContainer {
  border-right: 1px solid #e8e8e8;
  box-sizing: border-box;
  z-index: 1;
}

.sidebarList {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0 1rem;
  /* width: 100%; */
  height: 100vh;
  background-color: #fefefe;
  list-style-type: none;
  overflow: none;
}

.sidebarList li {
  width: 100%;
  padding: 0.25rem 0;
  overflow: none;
}

.sidebarList li:nth-child(1) {
  padding-top: 0.5rem;
  padding-bottom: 2rem;
}

.logo {
  display: flex;
  justify-content: start;
  padding-top: 0.25rem;
}

.logo img {
  width: 8rem;
  border: #fefeff;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  gap: 0.75rem;
}

.menu p {
  font-size: 0.875rem;
}

.PiFramerLogoFill {
  stroke-width: 5;
}

.navItemBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 2rem;
  border: #fefeff;
  border-radius: 0.375rem;
  padding: 0 0.75rem;
  text-decoration: none;
  color: #706f76ed;
  font-weight: 500;
}

.navItemBoxActive {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 2rem;
  border: #fefeff;
  border-radius: 0.375rem;
  padding: 0 0.75rem;
  text-decoration: none;
  color: #432ca8ba;
  font-weight: 500;
  background-color: #0a02eb12;
}

.navItemBox:hover {
  background-color: #0a02eb12;
  color: #432ca8ba;
}
