.inputDropDown {
  margin-top: 0.625rem;
}

.inputDropDownHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.5rem;
  background-color: #b4b3d412;
  border: 1px solid #5150952b;
  border-radius: 0.375rem;
  box-sizing: border-box;
  font-size: 0.813rem;
  text-align: right;
  margin: 0;
  font-weight: 400;
  color: #656565;
}

button {
  border: none;
  box-sizing: border-box;
}

.inputDropDownHeading span {
  display: flex;
  align-items: center;
  margin: 0 1rem 0 0.75rem;
  color: #6c6685;
}

.inputDropDownContent {
  transition: all 0.3s ease-in-out;
  background-color: #b4b3d412;
  border: 1px solid #5150952b;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

/* DropdownItem */

.item {
  width: 100%;
  background-color: transparent;
  border-radius: 0;
}

.item:nth-child(n + 2) {
  border-top: 1px solid #5150952b;
}

.item:active {
  background-color: #b4b3d42e;
  cursor: pointer;
}
