.cardContainer {
  display: flex;
  align-items: center;
  height: 5rem;
}
.cardContainer {
  cursor: pointer;
}

.cardHandle {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 3rem;
  height: 100%;
}

.cardInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 0.75rem;
  padding: 1rem;
  width: calc(100% - 3rem);
  border: 1px solid #8080804d;
  border-radius: 0.375rem;
  background-color: #8080800a;
  box-sizing: border-box;
}

.cardInfoContainerSelected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 0.75rem;
  padding: 1rem;
  width: calc(100% - 3rem);
  border: 1px solid #807df766;
  border-radius: 0.375rem;
  background-color: #001aff11;
  box-sizing: border-box;
}

.cardQuestionInfo {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0.5rem;
  width: 100%;
}

.questionNumber {
  color: #696969;
  font-size: 1rem;
  font-weight: 500;
}

.questionTypeTag {
  border-radius: 0.125rem;
  padding: 0.125rem 0.25rem;
  color: #fff;
  background-color: #0f154c;
  font-size: 0.625rem;
  font-weight: 600;
}

.titlePreview {
  font-size: 0.825rem;
  font-weight: 600;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Empty State */
