.trivia-page {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: #d1d1f7;
}

.backButton {
  height: 2rem;
  width: 2rem;
  background-color: #ffffff;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: #9b9ede64 0px 15px 50px -12px;
}

.backButton:active {
  background-color: #f9f9f9;
}

.trivia-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.7rem 0.7rem;
  width: 37.5rem;
  height: 43.75rem;
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: #9b9ede64 0px 15px 50px -12px;
}

.image-container {
  width: auto;
  height: 40%;
  background-color: rgba(139, 139, 139, 0.779);
  border-radius: 0.3rem;
}

.question-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.3rem;
}

.question-count {
  display: flex;
  align-items: baseline;
}

.question-count p {
  margin: 1rem 0 0 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  color: #6c6684;
}

.question-title {
  display: flex;
  align-items: baseline;
  text-align: left;
}

.question-title h1 {
  margin: 1rem 0 0 0.5rem;
  font-weight: 700;
  font-size: 1.375rem;
  color: #2b1c50;
}

.answer-option-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  margin: 1rem 0;
  height: 100%;
}

.answer-option {
  padding: 0 1rem;
  height: 100%;
  border: none;
  border-radius: 0.5rem;
  text-align: left;
  font-weight: 500;
  font-size: 1rem;
  color: #2b1c50;
  background-color: #eff0ff;
}

.answer-option-green {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  border: none;
  border-radius: 0.5rem;
  text-align: left;
  font-weight: 500;
  font-size: 1rem;
  color: #eff0ff;
  background-color: #44ac5be4;
}

.answer-option-red {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  border: none;
  border-radius: 0.5rem;
  text-align: left;
  font-weight: 500;
  font-size: 1rem;
  color: #eff0ff;
  background-color: #e94545e9;
}

.answer-option:hover {
  cursor: pointer;
}

.button {
  display: flex;
  justify-content: center;
}

.button-disabled {
  width: 10rem;
  height: 2.5rem;
  background-color: #b2b3f1;
  color: #eff0ff;
  font-weight: 700;
  border: solid;
  border-width: 0.25rem;
  border-radius: 3rem;
  border-color: #b2b3f1;
  transition: transform 0.3s ease-in-out;
}

.button-enabled,
.button-enabled-result {
  width: 10rem;
  height: 2.5rem;
  background-color: #565add;
  color: #eff0ff;
  font-weight: 700;
  border: solid;
  border-width: 0.25rem;
  border-radius: 3rem;
  border-color: #b2b3f1;
  transition: transform 0.3s ease-in-out;
}

.button-enabled:hover,
.button-enabled-result:hover {
  cursor: pointer;
  transform: translateY(-0.188rem);
  box-shadow: #565add 0px 15px 50px -12px;
}

.button-enabled:active {
  cursor: pointer;
  border-color: #eff0ff;
}

.button-enabled-result:active {
  cursor: pointer;
  border-color: #d1d1f7;
}

/* Final Score Component Styles */

.final-result-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.7rem 0.7rem;
  width: 20rem;
  height: 25rem;
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: #9b9ede64 0px 15px 50px -12px;
}

.inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0.7rem 0.7rem;
  background-color: #d1d1f7;
  border-radius: 0.3rem;
  box-shadow: #9b9ede64 0px 15px 50px -12px;
}

.circle-score-bar {
  width: 55%;
  margin-top: 25%;
}

.final-score {
  margin: 0;
  transform: translate(0, -0.5rem);
  font-size: 1.125rem;
  font-weight: 700;
  color: #6c6684;
}

.final-score span {
  transform: translate(0, -0.5rem);
  font-size: 2rem;
  color: #2b1c50;
}

.final-score-subtitle {
  margin: 0;
  transform: translate(0, 0.375rem);
  font-size: 0.875rem;
  font-weight: 600;
  color: #6c6684;
}

.inner-container-secondary {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.final-result-caption {
  margin: auto;
  font-size: 1rem;
  font-weight: 600;
}
