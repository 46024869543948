.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
  height: calc(100vh - 146px);
  padding-top: 2rem;
}

.editorContainer {
  position: relative;
  width: 50%;
  height: 85%;
  background-color: #fff;
  border-radius: 0.5rem;
  /* box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px; */
  border: 1px solid #e2e2e2;
}

.editorInputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: start;
  height: 100%;
  width: 100%;
  padding: 1.5rem;
  gap: 1.5rem;
  box-sizing: border-box;
  overflow: auto;
}

.editorTitle {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 4rem;
  gap: 0.5rem;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e8e8e8;
}

h1 {
  margin: 0 0 0.25rem 0;
  font-size: 1.25rem;
  font-weight: 600;
}

p {
  margin: 0;
  font-size: 0.75rem;
  color: #656565;
}

.inputHeading {
  font-size: 0.825rem;
  font-weight: 500;
}

.inputForm {
  margin-top: 0.625rem;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.5rem;
  background-color: #b4b3d412;
  border: 1px solid #5150952b;
  border-radius: 0.375rem;
  box-sizing: border-box;
  font-size: 0.813rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputForm:has(input:focus) {
  border: 1px solid rgba(65, 64, 176, 0.763);
}

input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
}

input:focus {
  outline: none;
}

.removeInputButton {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0 0 0.5rem;
  background-color: transparent;
}

.removeInputButton:hover {
  cursor: pointer;
}

::placeholder {
  color: #7a7a7a97;
}

.inputFooter {
  display: flex;
  justify-content: right;
  gap: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  margin-top: auto;
}

button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  font-weight: 600;
  box-sizing: border-box;
}

button:hover {
  cursor: pointer;
}

.saveButton {
  border: 1px solid #e7e7e7;
  background-color: #f9f9f9;
}

.saveButton:enabled:active {
  background-color: #f4f4f4;
}

.saveAndAddButton {
  color: #fff;
  background-color: #565add;
}

.saveAndAddButton:enabled {
  background-color: #373bac;
}

.saveAndAddButton:disabled {
  color: #fff;
  background-color: #565bdd89;
}
/* Quiz Preview */

.quizPreviewContainer {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 1rem;
  flex: none;
  width: 25rem;
  height: 85%;
  /* box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px; */
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 2rem 1.5rem 2rem 0rem;
  box-sizing: border-box;
  overflow: auto;
  border: 1px solid #e2e2e2;
}

.quizPreviewContainer::-webkit-scrollbar {
  display: none;
}

.footer {
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 2rem;
  border-top: 1px solid #e8e8e8;
  background-color: #fefefe;
}

/* Footer */

.createQuizButton {
  color: #fff;
  background-color: #565add;
}

.createQuizButton:enabled {
  background-color: #373bac;
}

.createQuizButton:disabled {
  color: #fff;
  background-color: #565bdd89;
}
