.mainContainer {
  position: absolute;
  right: -20rem;
  top: 1.25rem;
  animation: slideInOut 4s 1;
}

/* Keyframes to define the sliding effect */
@keyframes slideInOut {
  0% {
    transform: translateX(0); /* Start off the screen to the right */
  }
  15% {
    transform: translateX(-106.25%); /* Start off the screen to the right */
  }
  90% {
    transform: translateX(-106.25%); /* Start off the screen to the right */
  }

  100% {
    transform: translateX(0); /* Start off the screen to the right */
  }
}

.toastContainer {
  border-radius: 0.5rem;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 5rem;
  width: 20rem;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 5px 15px 0px;
}

.colourAccentOrange {
  position: absolute;
  bottom: 0;
  background-color: #f7ad1e;
  height: 0.275em;
  width: 100%;
}
.colourAccentGreen {
  position: absolute;
  bottom: 0;
  background-color: #37f71e;
  height: 0.275em;
  width: 100%;
}
.colourAccentRed {
  position: absolute;
  bottom: 0;
  background-color: #f7341e;
  height: 0.275em;
  width: 100%;
}

.innerToastContainer {
  display: flex;
  align-items: center;
  height: 2.5rem;
}

.iconContainerOrange {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 2.5rem;
  background-color: #f7c3755c;
  border-radius: 0.5rem;
  margin: 0 1.5rem;
}
.iconContainerGreen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 2.5rem;
  background-color: #82f7755c;
  border-radius: 0.5rem;
  margin: 0 1.5rem;
}
.iconContainerRed {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 2.5rem;
  background-color: #f775755c;
  border-radius: 0.5rem;
  margin: 0 1.5rem;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.textContainer h3 {
  font-size: 1rem;
  margin: 0;
}
.textContainer p {
  margin: 0;
  font-size: 0.75rem;
  color: #656565;
}
