.profileDetailsContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.profileDetailsContainer > div:nth-child(1) {
  display: flex;
}

.profileDetailsContainer img {
  width: 2.5rem;
}

.username {
  margin-bottom: 0.125rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #212121;
}

.userLevel {
  font-size: 0.75rem;
  font-weight: 500;
  color: #6c6685;
}

.userPoints {
  padding: 0.125rem 0.375rem;
  border-radius: 0.625rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #3d2d7b;
  background-color: #f0f1ff;
}

/* Profile Body */

.heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: #212121;
  margin-bottom: 2rem;
}

/* Attempt Data Tiles */

.titlesLayoutContainer {
  display: block;
  height: calc(100vh - 330.5px);
  overflow: auto;
}

.titlesLayoutContainer::-webkit-scrollbar {
  display: none;
}

.titlesLayout {
  display: grid;
  grid-template-columns: 35% 25% 20% 20%;
}

.titlesLayout div {
  text-align: right;
}

.tileContainer {
  border: 1px solid #6c668533;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 1rem;
}

.tileInfoContainer {
  padding: 1.5rem 0;
  align-items: center;
  border-bottom: 1px solid #6c668533;
  background-color: #f0f1ff;
  color: #3d2d7b;
  font-weight: 500;
  font-size: 0.875rem;
}

.responseTable {
  border-spacing: 0 1rem;
  width: 100%;
  font-size: 0.825rem;
  color: #212121;
  border-collapse: separate;
  line-height: 1.25rem;
}

.responseTable td {
  margin: 0;
  padding: 0;
}
.responseTable thead td {
  margin: 0;
  padding: 0;
  font-weight: 600;
}

.responseTable td:nth-child(1) {
  width: 5%;
  text-align: center;
}
.responseTable td:nth-child(2) {
  width: 45%;
  padding-right: 1rem;
}
.responseTable td:nth-child(3) {
  width: 25%;
  text-align: left;
}
.responseTable td:nth-child(n + 4) {
  width: 25%;
  text-align: right;
}

.responseTable tbody tr {
  border: solid;
}

.correctAnswerTrue {
  display: inline;
  background-color: #17aa1dcc;
  color: #fff;
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
}

.correctAnswerFalse {
  display: inline;
  background-color: #d60b0bbf;
  color: #fff;
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
}
