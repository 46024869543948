.accordionHeading {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  border: none;
  background-color: #fff;
  text-align: right;
  color: #6c6685;
  font-size: 0.825rem;
  padding: 0.75rem 0;
}

.accordionHeading span {
  display: flex;
  align-items: center;
  margin: 0 1rem 0 0.75rem;
  color: #6c6685;
}

.accordionContent {
  transition: all 0.3s ease-in-out;
}
