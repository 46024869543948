.homepage {
  display: grid;
  grid-template-columns: 14rem auto;
  width: 100%;
  height: 100vh;
  margin: 0;
  background-color: #fcfcfe;
  /* overscroll-behavior: contain; */
}

.mainContainer {
  grid-column-start: 2;
  overflow: auto;
}

.mainContainer p {
  margin: 0;
  grid-column-start: 2;
}

.headerSection {
  border-bottom: 2px solid #333;
  height: 7rem;
}

/* Stat Section */

.statSection {
  display: flex;
  gap: 1.5rem;
  margin: 2rem 0;
}

.statContainer {
  display: flex;
  flex-direction: column;
  width: 16rem;
  height: 8rem;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 0.75rem;
  padding: 0 1rem;
  box-sizing: border-box;
  box-shadow: rgba(179, 177, 189, 0.106) 0px 2px 10px 0.1px;
}

.statContainer h2 {
  color: #2b1c50;
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
}

.statContainer > div {
  margin: 0;
  height: 50%;
  width: auto;
}

.statContainer > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.statContainer > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: start;
  width: auto;
}

.linkContainer {
  border: 1px solid #e8e8e8;
  border-radius: 0.25rem;
  padding: 0.375rem;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.875rem;
}

/* Quiz Section Contents */

.contentSection {
  width: calc(100vw - 19.063rem);

  margin: 0 2rem;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sectionContainer h1 {
  color: #2b1c50;
  margin: 2rem 0 0rem 0rem;
}

.sectionCarousel {
  display: flex;
  overflow: auto;
}

.sectionCarousel::-webkit-scrollbar {
  display: none;
}

.quizCardContainer {
  text-decoration: none;
}

.quizCard {
  display: flex;
  flex-direction: column;
  margin: 2rem 1rem;
  box-sizing: border-box;
  /* width: 21rem;
  height: 18rem; */
  width: 19rem;
  height: 18rem;
  border-radius: 0.75rem;
  box-shadow: rgba(179, 177, 189, 0.106) 0px 2px 10px 0.1px;
}

.imageContainer {
  width: auto;
  height: 55%;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.75rem 0.75rem 0 0;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 1.5rem;
  height: 100%;
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  border-radius: 0 0 0.75rem 0.75rem;
  overflow: hidden;
  background-color: #fff;
}

.quizCardTitle {
  font-size: 1.125rem;
  font-weight: 700;
  color: #2b1c50;
}

.quizCardCaption {
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 400;
  color: #6c6684;
  margin-top: 1rem;
  line-height: 140%;
}
