.headerContainer {
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 2rem;
  border-bottom: 1px solid #e8e8e8;
  background-color: #fefefe;
  /* box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px; */
  position: sticky;
  top: 0;
}

.profileTag {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 2rem;
  border: 1px solid #1821f430;
  padding: 0.375rem;
  box-sizing: border-box;
  gap: 0.5rem;
  background-color: #d5d4ff1a;
  border-radius: 0.375rem;
}

.profileAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.profileAvatar img {
  height: 100%;
}

.username {
  font-size: 0.875rem;
  color: #2b1c50;
  font-weight: 600;
}
